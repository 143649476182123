
import Vue from 'vue';

export default Vue.extend({
  components: {
    companyLogo: () => import('../global/companyLogo.vue'),
    MainFooter: () => import('../global/footer.vue'),
  },
  data: () => ({}),
  computed: {
    buildInfo(): string {
      if (process && process.env && process.env.VUE_APP_BUILD_VERSION && process.env.VUE_APP_BUILD_VERSION.length > 8) {
        return `v.1.${process.env.VUE_APP_BUILD_VERSION.substr(3, 6)}`;
      }
      return 'n/a';
    },
  },
});
